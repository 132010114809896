import { axiosInstance } from "../../constants/axiosInstance";
import { ACTION_TYPES } from "../ActionTypes/ActionTypes";
import { selectProgressBarState } from "./ProgressBarActions";

export const addProduct = (formData, navigate, alert, setIsOpen,) => {
    return async (dispatch) => {
        try {
            console.log('Adding product to server db');
            
            const response = await axiosInstance.post("/api/v1/product/addproduct", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' 
                  }
            });
            if (response.data.success) {
                alert.show('Product added successfully', {
                    onClose: () => {
                        navigate('/products');
                        setIsOpen(false);
                    }
                });
                dispatch({
                    type: ACTION_TYPES.SET_PRODUCT,
                    payload: response.data.data
                });
            } else {
                alert.show('Error while adding product');
            }
        } catch (error) {
            console.error('Error adding product:', error);
            alert.show('Error submitting the form. Please try again.');
        }
    }
};
  
export const updateProduct = (values, formData, navigate, alert, setIsOpen) => {
    console.log('Updating product');
    return async (dispatch) => {
        try {
            const response = await axiosInstance.patch('/api/v1/product/updateproduct', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }, params: {
                  values,
                  id: global.editId,
                },
            });

            if (response.data.success) {
                alert.show('Product updated successfully', {
                    onClose: () => {
                        navigate('/products');
                        setIsOpen(false);
                    }
                });
                setTimeout(() => {
                    setIsOpen(false);
                    navigate('/products');
                }, 5000);
                dispatch({
                    type: ACTION_TYPES.UPDATE_PRODUCT,
                    payload: response.data.data
                });
            } else {
                alert.show('Error while updating product');
            }
        } catch (error) {
            console.error('Error updating product:', error);
            alert.show('Error submitting the form. Please try again.');
        } finally {
            dispatch(selectProgressBarState(false));
        }
 };
}

export const getProducts = () => {
  return async (dispatch) => {
    dispatch(selectProgressBarState(true));
    const res = await axiosInstance.get("/api/v1/product/getproducts");
    if (res.data.success === true) {
      dispatch(selectProgressBarState(false));
      dispatch({
        type: ACTION_TYPES.GET_PRODUCTS,
        payload: res.data.data,
      });
    } else {
      dispatch(selectProgressBarState(false));
      alert.show("No Products Found");
      dispatch({
        type: ACTION_TYPES.GET_PRODUCTS,
        payload: [],
      });
    }
  };
};

export const deleteProducts = (id, navigate, alert) => {
  return async (dispatch) => {
    dispatch(selectProgressBarState(true));
    const res = await axiosInstance.delete("/api/v1/product/deleteproducts", {
      params: {
        IDS: id,
      },
    });
    if (res.data.success === true) {
      dispatch(selectProgressBarState(false));
      dispatch({
        type: ACTION_TYPES.DELETE_PRODUCTS,
        payload: id,
      });
      alert.show("deleted successfully", {
        onClose: () => {
          // navigate('/main/products')
          navigate("/products");
        },
      });
      setTimeout(() => {
        // navigate('/main/products')
        navigate("/products");
      }, 5000);
    } else {
      dispatch(selectProgressBarState(false));
      alert.show("Error in deletion");
    }
  };
};
