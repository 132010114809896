import cannabisForm from "../../assets/budbros-form.jpg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Loader } from "rsuite";
import ImageHolder from "../../assets/upload.svg";
import "rsuite/dist/rsuite.min.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { baseURL } from "../../constants/baseURL";
import { updateProduct, addProduct } from "../../redux/Actions/ProductActions";
import { axiosInstance } from "../../constants/axiosInstance";

const ProductSchema = (isAdd = true) =>
  Yup.object().shape({
    productPhoto: Yup.mixed().test(
      "fileRequired",
      "Image is required",
      (value) => (isAdd ? value != null : true)
    ),
    id: Yup.string(),
    name: Yup.string().required("Required"),
    shopid: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    subCategory: Yup.string().required("Required"),
    brand: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

export const AddProductsForm = (props) => {
  const status = !props.isAdd ? props.isAdd : true;
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(null);
  const [brandField, setBrandField] = useState("");
  const [editItem, setEditItem] = useState([]);
  const [shops, setShops] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [brandType, setBrandType] = useState([]);
  const [imgCheck, setImgCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let ShopsArray = [];

  const { products } = useSelector((state) => state.productReducer);

  const token = useSelector((state) => state.ProfileReducer);

  useEffect(() => {
    getCategory();
    if (!status) {
      setEditItem(products.filter((product) => product._id === global.editId));
      loadData();
    }
  }, []);

  const initialValues = {
    productPhoto: editItem.length > 0 ? editItem[0].productPhoto : null,
    name: editItem.length !== 0 ? `${editItem[0].name}` : "",
    description: editItem.length !== 0 ? `${editItem[0].description}` : "",
    shopid: editItem.length !== 0 ? `${editItem[0].shopid}` : "",
    brand: brandField,
    category: editItem.length > 0 ? editItem[0].category : [],
    subCategory: editItem.length > 0 ? editItem[0].subCategory : [],
    type: editItem.length > 0 ? editItem[0].type : [],
    price: editItem.length !== 0 ? `${editItem[0].price}` : "",
  };

  const loadData = async () => {
    let brandName = products.filter((product) => {
      return product.brand;
    });
    setBrandField(brandName[0].brand);
    axiosInstance
      .get("/api/v1/category/getsinglebrand", {
        params: { brand: brandName[0].brand },
      })
      .then((res) => {
        if (res.data.success) {
          setCategory(res.data.data.category);
          setSubCategory(res.data.data.subCategory);
          setBrandType(res.data.data.type);
          console.log(res.data.data, "Brand data");
        } else {
          alert.show("No Category Found");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCategory = async () => {
    try {
      const res = await axiosInstance.get("/api/v1/category/getcategories");

      if (res.data.success) {
        axiosInstance
          .get("/api/v1/admin/getradius", {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              response.data.data.forEach(function (restu) {
                let obj = {
                  title: restu.shopName,
                  id: restu._id,
                  delivery: restu.delivery,
                };
                ShopsArray.push(obj);
              });
              setShops(ShopsArray);
              setCategoryItems(res.data.data);
            } else {
              alert.show("No Category Found");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        alert.show("No Category Found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBrand = async (event, setFieldValue) => {
    event.preventDefault();

    try {
      setFieldValue("brand", event.target.value);
      axiosInstance
        .get("/api/v1/category/getsinglebrand", {
          params: {
            brand: event.target.value,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setFieldValue("category", res.data.data.category[0]);
            setCategory(res.data.data.category);
            setFieldValue("subCategory", res.data.data.subCategory[0]);
            setSubCategory(res.data.data.subCategory);
            setFieldValue("type", res.data.data.type[0]);
            setBrandType(res.data.data.type);
          } else {
            alert.show("No Category Found");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const formData = new FormData();
    if (values.productPhoto instanceof File) {
      formData.append("productPhoto", values.productPhoto);
    }
    formData.append("id", editItem.length !== 0 ? editItem[0]._id : "");
    formData.append("name", values.name);
    formData.append("shopid", values.shopid);
    formData.append("brand", values.brand);
    formData.append("category", values.category);
    formData.append("subCategory", values.subCategory);
    formData.append("type", values.type);
    formData.append("price", values.price);
    formData.append("description", values.description);
    formData.append("delivery", values.delivery);
    try {
      const action = status ? addProduct : updateProduct;
      dispatch(action(values, formData, navigate, alert, props.modal));
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert.show("Error submitting the form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="w-full h-[85vh]">
        <div
          style={{ scrollbarWidth: "thin" }}
          className="container h-full mx-auto overflow-y-scroll"
        >
          <div className="flex justify-center">
            <div className="w-full flex ">
              <div
                className="w-2/3 h-auto  lg:block lg:w-5/12 bg-cover md:hidden "
                style={{
                  backgroundImage: `linear-gradient( to right, rgba(0,0,0,0.2) ,rgba(0, 0, 0, 0.2)) ,url(${cannabisForm})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>

              <div className="w-full xl:w-[65%] md:w-full bg-white rounded-lg ">
                <h3 className="pt-4 text-2xl text-center mt-8 font-bold">
                  {!status ? "Product Details" : "Add Product"}
                </h3>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={ProductSchema(!status)}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => {
                    return (
                      <Form className="px-8 pt-6 pb-8 mb-4  bg-white rounded">
                        <div className="flex mx-auto justify-center">
                          <div className=" md:mr-2 md:mb-0 md:w-full justify-center mx-auto">
                            <label
                              htmlFor="upload"
                              className="w-[120px] h-[120px] block rounded-[50%] cursor-pointer mx-auto mb-2 bg-neutral-50"
                            >
                              <img
                                className="w-[125px] h-[125px] block rounded-[50%] cursor-pointer mb-2 "
                                src={
                                  !status && editItem.length !== 0 && !imgCheck
                                    ? baseURL + editItem[0].productPhoto
                                    : !values.productPhoto
                                    ? ImageHolder
                                    : filePreview
                                }
                                alt="product img"
                              />
                              <input
                                className="hidden"
                                id="upload"
                                name="image"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                  const file =
                                    event.currentTarget.files &&
                                    event.currentTarget.files[0];
                                  const ne = URL.createObjectURL(file);
                                  console.log({ values, ne });
                                  setFieldValue("productPhoto", file);
                                  setFilePreview(URL.createObjectURL(file));
                                  setImgCheck(true);
                                }}
                              />
                            </label>
                            <label
                              className="block mb-2 text-sm font-bold text-gray-700 md:mt-2 text-center "
                              name="productPhoto"
                            >
                              Product Image
                            </label>
                            <ErrorMessage
                              className="text-red-600 text-xs text-center"
                              name="productPhoto"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="flex justify-around ">
                          <div className="flex flex-col">
                            <div className="flex flex-col justify-around  my-3">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="name"
                                  >
                                    Name
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="text"
                                    name="name"
                                    placeholder="Enter product name"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="name"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
                                    htmlFor="shopid"
                                  >
                                    Pick Shop
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="shopid"
                                    value={values.shopid}
                                    onChange={(event) => {handleBrand(event); setFieldValue("shopid", event.target.value)}} 
                                  >
                                    <option value="" hidden>
                                      Select Shop Here
                                    </option>
                                    {shops.map((shop, id) => (
                                      <option key={id} value={shop.id}>
                                        {shop.title}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="shopid"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
                                    htmlFor="type"
                                  >
                                    Brand
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="brand"
                                    onChange={(event) => {
                                      handleBrand(event, setFieldValue);
                                      handleChange(event);
                                    }}
                                  >
                                    <option value="" hidden selected>
                                      Select Brand Here
                                    </option>
                                    {categoryItems.map((category, index) => (
                                      <option
                                        key={index}
                                        value={category.brand}
                                      >
                                        {category.brand}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="brand"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* {handleUserChange(values.brand)} */}
                            <div className="flex justify-around flex-col ">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="category"
                                  >
                                    Category
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="category"
                                    onChange={(event) => handleChange(event)}
                                  >
                                    <option value="" hidden selected>
                                      Select Category Here
                                    </option>
                                    {category.map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="category"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="subCategory"
                                  >
                                    Sub-Category
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="subCategory"
                                  >
                                    <option value="" hidden selected>
                                      Select Sub-Category Here
                                    </option>
                                    {subCategory.map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="subCategory"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col justify-around my-2">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2  text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="type"
                                  >
                                    Type
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="type"
                                  >
                                    <option value="" hidden selected>
                                      Select Type Here
                                    </option>
                                    {brandType.map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="type"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex flex-col justify-around my-3">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 "
                                    htmlFor="description"
                                  >
                                    Description
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="textarea"
                                    type="text"
                                    rows="5"
                                    name="description"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="description"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 mt-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="price"
                                  >
                                    Price
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="number"
                                    name="price"
                                    id="price"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="price"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 flex items-center justify-center gap-2 sm:flex-col text-center">
                          <button
                            className="flex items-center justify-center cursor-pointer gap-2 w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                            type="submit"
                              disabled={isSubmitting}
                          >
                            {!status ? "Update" : "Submit"}{" "}
                            {isLoading && <Loader />}
                          </button>
                          <button
                            className={`w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline ${
                              !status ? "hidden" : ""
                            }`}
                            type="reset"
                          >
                            Reset
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
