import logo2 from "../../assets/logo.png";
import { useState, useEffect } from "react";
import dashboardHome from "../../assets/dashboard-home.svg";
import product from "../../assets/product.svg";
import order from "../../assets/order.svg";
import driver from "../../assets/driver.svg";
import category from "../../assets/category.svg";
import logout from "../../assets/logout.svg";
import { IconBg } from "../minor-components/IconBg";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../constants/axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideBar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [restaurantArray, setrestaurantArray] = useState([]);

  let ShopsArray = [];

  const token = useSelector((state) => state.ProfileReducer);
  useEffect(() => {
    if (token) {
      getShopName();
    }
  }, [token]);

  const getShopName = async () => {
    const res = await axiosInstance.get("/api/v1/admin/getradius", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    if (res.data.success) {
      res.data.data.forEach(function (restu) {
        let obj = {
          title: restu.shopName,
        };
        ShopsArray.push(obj);
      });
      setrestaurantArray(ShopsArray);
    } else {
      alert.show("Not Found");
    }
  };

  const sidebarData = [
    {
      title: "Dashboard",
      path: "/",
      svg: dashboardHome,
    },
    {
      title: "Categories",
      path: "/categories",
      svg: category,
    },
    {
      title: "Products",
      path: "/products",
      svg: product,
    },
    {
      title: "Orders",
      path: "/orders",
      svg: order,
    },
    {
      title: "Shops",
      path: "/shops",
      childrens: restaurantArray,
      svg: product,
    },
    {
      title: "Users",
      path: "/users",
      svg: driver,
    },
    {
      title: "Drivers",
      path: "/drivers",
      svg: driver,
    },
    // {
    //   title: "Earning",
    //   path: "/earnings",
    //   svg: driver,
    // },
    {
      title: "Website Pages",
      path: "/pages",
      svg: category,
    },
    {
      title: "Logout",
      path: "/logout",
      svg: logout,
    },
  ];

  const loadWindow = (e) => {
    const restaurantName = e.target.innerHTML;
    const res = restaurantName.toLowerCase();
    navigate(`/singleshop/${res}`, { state: { restaurantName: res } });
    window.location = `/singleshop/${res}`;
  };

  return (
    <>
      <div
        style={{ scrollbarWidth: "none" }}
        className=" shadow-xl bg-white fixed overflow-y-auto scroll-thin top-0 h-full left-0 w-[18%] 
             md:hidden"
      >
        <div className=" h-24 bg-gray-50 flex justify-center items-center p-0 m-0">
          <img className="mx-auto mt-0 w-[85px]" src={logo2} alt="logo" />
        </div>
        <ul className="flex flex-col">
          {sidebarData.map((item, index) => (
            <li
              key={index}
              className={`font-semibold p-5 flex justify-between  cursor-pointer`}
            >
              <IconBg svg={item.svg} />
              <div className="flex-1 flex justify-between items-center  pl-[20%] text-xs">
                {item.path === "/logout" ? (
                  <p
                    className="text-gray-800"
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                    }}
                  >
                    {item.title}
                  </p>
                ) : item.childrens ? (
                  <div
                    className={
                      open ? "sidebar-item w-full open" : "sidebar-item w-full"
                    }
                  >
                    <div className="sidebar-title w-full flex justify-between ">
                      <span>
                        {item.icon && <i className={item.icon}></i>}
                        {item.title}
                      </span>
                      <FontAwesomeIcon
                        className=" bi-chevron-down toggle-btn "
                        icon="fa-solid fa-angle-up fa-2xl"
                        onClick={() => setOpen(!open)}
                        size="lg"
                      />
                    </div>
                    <div className="sidebar-content">
                      <ul className="flex flex-col">
                        <li className=" p-1 mt-2  flex justify-between  cursor-pointer">
                          <NavLink
                            end
                            to="/all-shops"
                            className={({ isActive }) =>
                              isActive
                                ? "text-myBg block ml-2"
                                : "text-gray-800 block ml-2"
                            }
                          >
                            All Shops
                          </NavLink>
                        </li>
                        <li className=" p-1 mt-2  flex justify-between  cursor-pointer">
                          <NavLink
                            end
                            to="/add-shop"
                            className={({ isActive }) =>
                              isActive
                                ? "text-myBg block ml-2"
                                : "text-gray-800 block ml-2"
                            }
                          >
                            Add Shop
                          </NavLink>
                        </li>
                        {item.childrens.map((child, index) => (
                          <li className="font-semibold p-1 mt-2  flex justify-between  cursor-pointer" key={index}>
                            <NavLink
                              
                              end
                              to={{ pathname: child.path }}
                              onClick={loadWindow}
                              state={{
                                restaurantName: `${child.title}`,
                              }}
                              className={({ isActive }) =>
                                isActive
                                  ? "text-myBg block ml-2"
                                  : "text-gray-800 block ml-2"
                              }
                            >
                              {child.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <NavLink
                    end
                    to={item.path}
                    className={({ isActive }) =>
                      isActive ? "text-myBg" : "text-gray-800"
                    }
                  >
                    {item.title}
                  </NavLink>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default SideBar;
